
import {defineComponent, ref, watch} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import InfoBox from "@/components/base/InfoBox.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "HasOtherClaims",
  components: {InfoBox},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref([]);
    const load = (id) => {
      ClaimService.hasOtherClaim(id).then(res => {
        data.value = res
      })
    }
    load(props.id)
    watch(() => props.id, (cb) => {
      load(cb)
    })
    return {
      data,
      ...LoadPanel()
    }
  }
})
